var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-tabs",
        [
          _c(
            "a-tab-pane",
            { key: "erp", attrs: { tab: "ERP布局模板" } },
            [_c("erp-template")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "布局模板1" } },
            [_c("template1")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "布局模板2" } },
            [_c("template2")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "布局模板3" } },
            [_c("template3")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "4", attrs: { tab: "布局模板4" } },
            [_c("template4")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "5", attrs: { tab: "布局模板5" } },
            [_c("template5")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }